<template>
  <div v-if="question">
    <sqr-input-text
      label="SKU from Stripe"
      :value="question.sku"
      @change="propSet('sku', $event)"
    />
    <p class="text-xs mt-1">
      Visit
      <a
        class="text-blue-600 hover:text-blue-400"
        href="https://dashboard.stripe.com/products"
        target="_blank"
        >Stripe / Products</a
      >
      to configure products and
      <abbr title="Stock Keeping Unit">SKUs</abbr>.
    </p>
    <sqr-input-number
      class="mt-3"
      label="Minimum order"
      placeholder="Minimum order of item to order"
      :value="question.min"
      @change="propSet('min', $event)"
    />
    <sqr-input-number
      class="mt-3"
      label="Maximum order"
      placeholder="Maximum order of item to order"
      :value="question.max"
      @change="propSet('max', $event)"
    />
  </div>
</template>

<script>
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';
import base from '../base';
export default {
  name: 'ProductEdit',
  mixins: [base],
  components: { SqrInputNumber, SqrInputText },
};
</script>
